<template>
  <div class="footer">
    <div class="footer__container">
      <div class="footer__info">
        <h5>{{ $t("footer.name") }}</h5>
        <p>
          {{
            $t("footer.organization", { organization_number: "769616-3372" })
          }}
        </p>
      </div>
      <div class="footer__contact">
        <div class="footer__contact__content">
          <h5>{{ $t("footer.contact") }}</h5>
          <p><a href="mailto:styrelsen@brfpalett.se">styrelsen@brfpalett.se</a></p>
          <div class="footer__contact__addresses">
            <p class="footer__contact__addresses__address">
              <strong>{{ $t("footer.address.postal") }}</strong
              ><br />
              BRF Palett<br />
              Mjölnerbacken 76 lgh 1103<br />
              174 60 Sundbyberg
            </p>
            <p class="footer__contact__addresses__address">
              <strong>{{ $t("footer.address.invoice") }}</strong
              ><br />
              HSB Brf Palett<br />
              Ref 99224980<br />
              FE 391<br />
              838 73 FRÖSÖN
            </p>
          </div>
        </div>
      </div>
      <div class="footer__links">
        <a href="https://www.facebook.com/brfpalett" target="_blank"
          ><i class="fa-facebook-square fab" />
          {{ $t("footer.facebook_link") }}</a
        >
        <toggle-component
          v-if="showEditToggle"
          class="footer__links__edit"
          :modelValue="isEdit"
          @update:modelValue="updateEdit"
        >
          {{ $t("footer.edit_toggle") }}
        </toggle-component>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
// Libs
import { computed, ref } from "vue";

// Components
import ToggleComponent from "./toggle.component.vue";

// Bootstrap
import { authService, storageService } from "../bootstrap";

const isAuth = computed<boolean>(() => authService.isAuth);
const showEditToggle = computed<boolean>(() => isAuth.value);
const isEdit = ref<boolean>(storageService.get<boolean>("edit", false));
storageService.on("edit", (edit: boolean) => {
  isEdit.value = edit;
});
const updateEdit = (edit: boolean) => {
  storageService.set("edit", edit);
};

const edit = ref<boolean>(false);
</script>
<style lang="scss" scoped>
@import "../../scss/base";
.footer {
  padding: 10px 10px 0px;
  min-height: $footer-height;
  background-color: #111;
  color: #fff;
  &__container {
    @include container;
    @include desktop {
      display: flex;
      justify-content: space-between;
    }
  }
  &__info,
  &__contact,
  &__links {
    flex-grow: 1;
    @include mobile {
      margin-bottom: 10px;
    }
  }
  &__contact {
    flex-grow: 1;
    display: flex;
    @include desktop {
      justify-content: center;
    }
    &__addresses {
      display: flex;
      &__address {
        margin-right: 20px;
        & strong {
          font-weight: bold;
          text-decoration: underline;
        }
      }
    }
  }
  &__links {
    @include desktop {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
    &__edit {
      margin-top: 10px;
      display: flex;
    }
  }
  a {
    color: $primary;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
